.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-vcard-container {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 15px;
}
.home-step1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  flex-direction: column;
  padding-bottom: 10px;
  background-color: #E8EEF2;
}
.home-title-bar {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  justify-content: flex-start;
}
.home-button {
  color: white;
  margin-right: 10px;
  background-color: rgb(25, 24, 24);
}
.home-card-types {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  padding-bottom: 30px;
}
.home-card-container {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-image {
  width: 200px;
  cursor: pointer;
  object-fit: cover;
  transition: 0.3s;
}
.home-image:hover {
  width: 215px;
}
.home-radiobutton {
  width: 17px;
  height: 17px;
  margin-top: 30px;
}
.home-card-container1 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-image1 {
  width: 200px;
  cursor: pointer;
  object-fit: cover;
  transition: 0.3s;
}
.home-image1:hover {
  width: 215px;
}
.home-radiobutton1 {
  width: 17px;
  height: 17px;
  margin-top: 30px;
}
.home-card-container2 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-image2 {
  width: 200px;
  cursor: pointer;
  object-fit: cover;
  transition: 0.3s;
}
.home-image2:hover {
  width: 215px;
}
.home-radiobutton2 {
  width: 17px;
  height: 17px;
  margin-top: 30px;
}
.home-card-container3 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-image3 {
  width: 200px;
  cursor: pointer;
  object-fit: cover;
  transition: 0.3s;
}
.home-image3:hover {
  width: 215px;
}
.home-radiobutton3 {
  width: 17px;
  height: 17px;
  margin-top: 30px;
}
.home-card-container4 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-image4 {
  width: 200px;
  cursor: pointer;
  object-fit: cover;
  transition: 0.3s;
}
.home-image4:hover {
  width: 215px;
}
.home-radiobutton4 {
  width: 17px;
  height: 17px;
  margin-top: 30px;
}
.home-step2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 40px;
  align-items: flex-start;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  flex-direction: column;
  padding-bottom: 10px;
  background-color: #E8EEF2;
}
.home-title-bar1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  margin-bottom: 25px;
  justify-content: flex-start;
}
.home-button1 {
  color: white;
  margin-right: 10px;
  background-color: rgb(25, 24, 24);
}
.home-form-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-form {
  flex: 0 0 auto;
  width: calc(100% - 240px);
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-label {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.home-left {
  flex: 1;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: flex-start;
}
.home-right {
  flex: 1;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: flex-start;
  margin-left: 15px;
}
.home-row {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}
.home-left1 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.home-textinput {
  width: 100%;
  height: 40px;
  border-color: var(--dl-color-gray-500);
}
.home-right1 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-left: 15px;
}
.home-textinput01 {
  width: 100%;
  height: 40px;
  border-color: var(--dl-color-gray-500);
}
.home-row1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.home-left2 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.home-textinput02 {
  width: 100%;
  height: 40px;
  border-color: var(--dl-color-gray-500);
}
.home-right2 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-left: 15px;
}
.home-textinput03 {
  width: 100%;
  height: 40px;
  border-color: var(--dl-color-gray-500);
}
.home-label2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: flex-start;
}
.home-left3 {
  flex: 1;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: flex-start;
}
.home-right3 {
  flex: 1;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: flex-start;
  margin-left: 15px;
}
.home-row2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}
.home-left4 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.home-textinput04 {
  width: 100%;
  height: 40px;
  border-color: var(--dl-color-gray-500);
}
.home-textinput05 {
  width: 100%;
  height: 40px;
  margin-left: 8px;
  border-color: var(--dl-color-gray-500);
}
.home-right4 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-left: 15px;
}
.home-textinput06 {
  width: 100%;
  height: 40px;
  border-color: var(--dl-color-gray-500);
}
.home-textinput07 {
  width: 100%;
  height: 40px;
  margin-left: 8px;
  border-color: var(--dl-color-gray-500);
}
.home-row3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}
.home-left5 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.home-textinput08 {
  width: 100%;
  height: 40px;
  border-color: var(--dl-color-gray-500);
}
.home-textinput09 {
  width: 100%;
  height: 40px;
  margin-left: 8px;
  border-color: var(--dl-color-gray-500);
}
.home-right5 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-left: 15px;
}
.home-textinput10 {
  width: 100%;
  height: 40px;
  border-color: var(--dl-color-gray-500);
}
.home-row4 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}
.home-left6 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.home-textinput11 {
  width: 100%;
  height: 40px;
  border-color: var(--dl-color-gray-500);
}
.home-right6 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-left: 15px;
}
.home-textinput12 {
  width: 100%;
  height: 40px;
  border-color: var(--dl-color-gray-500);
}
.home-textinput13 {
  width: 100%;
  height: 40px;
  margin-left: 8px;
  border-color: var(--dl-color-gray-500);
}
.home-vcardsample {
  flex: 0 0 auto;
  width: 220px;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-left: 15px;
  justify-content: center;
}
.home-image5 {
  width: 150px;
  object-fit: cover;
}
